@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "SCDream";
  font-style: normal;
  font-weight: 100;
  src: url("./fonts/SCDream1.otf") format("opentype");
}
@font-face {
  font-family: "SCDream";
  font-style: normal;
  font-weight: 200;
  src: url("./fonts/SCDream2.otf") format("opentype");
}
@font-face {
  font-family: "SCDream";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/SCDream3.otf") format("opentype");
}
@font-face {
  font-family: "SCDream";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/SCDream4.otf") format("opentype");
}
@font-face {
  font-family: "SCDream";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/SCDream5.otf") format("opentype");
}
@font-face {
  font-family: "SCDream";
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/SCDream6.otf") format("opentype");
}
@font-face {
  font-family: "SCDream";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/SCDream7.otf") format("opentype");
}
@font-face {
  font-family: "SCDream";
  font-style: normal;
  font-weight: 800;
  src: url("./fonts/SCDream8.otf") format("opentype");
}
@font-face {
  font-family: "SCDream";
  font-style: normal;
  font-weight: 900;
  src: url("./fonts/SCDream9.otf") format("opentype");
}

body {
  margin: 0;
  font-family: "SCDream", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}
